<template>
<br>
   <div class="home home--static">
    <section class="section">
        <div class="container">
            <div class="row">
               <div class="col-12">
                   <div class="section__carousel-wrap">
                       <div id="carousel-example" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner row w-100 mx-auto" role="listbox">
                <div class="carousel-item col-12 col-sm-6 col-md-4 col-lg-3 " :class="{ active: index === 0 }" v-for="(publish, index) in carousel" :key="index">
                    <a @click="getFeed(publish)" class="pointer">
                        <img :src="publish.carsoul_img" class="img-fluid mx-auto d-block" alt="img1">
                    </a>
                    <h3 class="interview__title pointer" style=" display: flex;z-index: 1;position: absolute;margin-top: -11%;font-family: 'Cairo', sans-serif;font-size: 18px;line-height: 28px;font-weight: 600;color: #002248;margin-right: 18%;background: #ffffffb0;width: 65%;right: 0;text-align: right;justify-content: center;border-radius: 12px;"><a @click="getFeed(publish)">{{ publish.title_ar}}</a></h3>
                </div>
                
            </div>
            <a class="carousel-control-prev" style="border-radius:  10px 0 0 10px ;" href="#carousel-example" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" style="border-radius: 0 10px 10px 0;" href="#carousel-example" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
                   </div>
               </div>
            </div>
        </div>
    </section>
</div>
  <div class="pc-tab">
    <input checked="checked" id="tab0" type="radio" name="pct" />
    <input id="tab1" type="radio" name="pct" />
    <input id="tab2" type="radio" name="pct" />
    <input id="tab3" type="radio" name="pct" />
    <input id="tab4" type="radio" name="pct" />
    <input id="tab5" type="radio" name="pct" />
    <input id="tab6" type="radio" name="pct" />
    <nav>
      <ul style="justify-content: center;" class="slider-radio">
      <li class="tab0">
          <label  for="tab0">كل الفنانين</label>
        </li>
        <!-- <li class="tab1">
          <label for="tab1">صحة</label>
        </li>
        <li class="tab2">
          <label for="tab2">تعليم</label>
        </li>
        <li class="tab3">
          <label for="tab3">قانون</label>
        </li> -->
        <li class="tab4">
          <label for="tab4">فن و دراما</label>
        </li>
        <li class="tab5">
          <label for="tab5">كوميديا</label>
        </li>
        <!-- <li class="tab6">
          <label for="tab6">رياضة</label>
        </li> -->
      </ul>
      
      
    </nav>

    <section>
    <div class="tab0">
      <div class="container"  >
      <div class="row">
        <div class="col-12" dir="rtl">
          <!-- <h3 class="card__title pointer" style="text-align: right;color: #0E6BA2;text-shadow: 1px 1px 2px #73707082;margin-bottom: 10px;">  الفنانين </h3> -->
      <div class="yt__filter-slider-wrapper" style="margin-top: 1vh;">
		<div class="yt__filter-slider">
			<ul class="yt__filter-list">
        <template v-for="publish in All" :key="publish.id">
				<li class="yt__filter-list-item "><h5 class="card__title pointer" style="font-family: 'Cairo', sans-serif;font-weight: 600;font-size: 14px;color: #9b9191;margin-top: 0;margin-bottom: 0;transition: 0.5s;display: block;width: 100%;overflow: hidden;white-space: nowrap;-o-text-overflow: ellipsis;text-overflow: ellipsis;word-wrap: break-word;text-shadow: 1px 1px 2px #00000008;">
          <a @click="GoToArtistsContent(publish)">   {{ publish.cp_name }}  </a></h5></li>
				
        </template>
			</ul>
		</div>
	</div>
      
      
    
        </div>
      <div class="col-12">
        <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
        <template v-for="publish in All" :key="publish.id">
                    
                    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" >
                        <div class="cardBox">
  <div class="card">
      <a @click="GoToArtistsContent(publish)" class="card__cover" style="height: 230px;">
                                        <img :src="publish.img" alt="">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                    </a>
                                    <h3 class="card__title" style="padding-bottom: 9px;margin-bottom: 7px;"><a @click="GoToArtistsContent(publish)">{{ publish.cp_name }}</a></h3>
                                    <ul style="justify-content: center;" class="card__list">
                                        <li style="margin-left: 0;">{{ publish.name_ar }}</li>
                                        <li></li>
                                    </ul>
    
  </div>
</div>
                    </div>
            </template>
        </div>
        </div>
        </div>
        </div>
      </div>
      <div class="tab1">
      <div class="container"  style="padding-left:0;padding-right:0">
      
        </div>
      </div>
      <div class="tab2">
      <div class="container" style="padding-left:0;padding-right:0">
     
        </div>
      </div>
      <div class="tab3">
      <div class="container" style="padding-left:0;padding-right:0">
      
        </div>
      </div>
      <div class="tab4">
      <div class="container" >
      <div class="row">
        <div class="col-12" dir="rtl">
          <!-- <h3 class="card__title pointer" style="text-align: right;color: #0E6BA2;text-shadow: 1px 1px 2px #73707082;margin-bottom: 10px;">  الفنانين </h3> -->
      <div class="yt__filter-slider-wrapper" style="margin-top: 1vh;">
		<div class="yt__filter-slider">
			<ul class="yt__filter-list">
        <template v-for="publish in Artists" :key="publish.id">
				<li class="yt__filter-list-item "><h5 class="card__title pointer" style="font-family: 'Cairo', sans-serif;font-weight: 600;font-size: 14px;color: #9b9191;margin-top: 0;margin-bottom: 0;transition: 0.5s;display: block;width: 100%;overflow: hidden;white-space: nowrap;-o-text-overflow: ellipsis;text-overflow: ellipsis;word-wrap: break-word;text-shadow: 1px 1px 2px #00000008;">
          <a @click="GoToArtistsContent(publish)">   {{ publish.cp_name }}  </a></h5></li>
				
        </template>
			</ul>
		</div>
	</div>
      
      
    
        </div>
      <div class="col-12">
        <h3 class="card__title pointer" style="text-align: right;color: #0E6BA2;text-shadow: 1px 1px 2px #73707082;margin-bottom: 10px;">  الفنانين </h3>
        <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
        <template v-for="publish in Artists" :key="publish.id">
                    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" >
                      <div class="cardBox">
  <div class="card">
      <a @click="GoToArtistsContent(publish)" class="card__cover" style="height: 230px;">
                                        <img :src="publish.img" alt="">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                    </a>
                                    <h3 class="card__title" style="padding-bottom: 9px;margin-bottom: 7px;"><a @click="GoToArtistsContent(publish)">{{ publish.cp_name }}</a></h3>
                                    <ul style="justify-content: center;" class="card__list">
                                        <li style="margin-left: 0;">{{ publish.name_ar }}</li>
                                        <li></li>
                                    </ul>
    
  </div>
</div>
                        <!-- <div class="card" >
                            <h3 class="card__title pointer"><a @click="getFeed(publish)">   {{ publish.title_ar }}  </a></h3>
                            <a @click="getFeed(publish)" class="card__cover pointer">
                                    <img :src="publish.img" alt="">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>                  
                            <ul class="card__list pointer">
                                <li>سوداني</li>
                                <li>{{ publish.name_ar }}</li>
                                <li>{{ publish.year }}</li>
                            </ul>
                        </div> -->
                    </div>
            </template>
        </div>
        </div>
        </div>
        </div>
      </div>
      <div class="tab5">
      <div class="container" >
      <div class="row">
        
 <div class="col-12" dir="rtl">
          <!-- <h3 class="card__title pointer" style="text-align: right;color: #0E6BA2;text-shadow: 1px 1px 2px #73707082;margin-bottom: 10px;">  الفنانين </h3> -->
      <div class="yt__filter-slider-wrapper" style="margin-top: 1vh;">
		<div class="yt__filter-slider">
			<ul class="yt__filter-list">
        <template v-for="publish in ArtistsComedy" :key="publish.id">
				<li class="yt__filter-list-item "><h5 class="card__title pointer" style="font-family: 'Cairo', sans-serif;font-weight: 600;font-size: 14px;color: #9b9191;margin-top: 0;margin-bottom: 0;transition: 0.5s;display: block;width: 100%;overflow: hidden;white-space: nowrap;-o-text-overflow: ellipsis;text-overflow: ellipsis;word-wrap: break-word;text-shadow: 1px 1px 2px #00000008;">
          <a @click="GoToArtistsContent(publish)">   {{ publish.cp_name }}  </a></h5></li>
				
        </template>
			</ul>
		</div>
	</div>
      
      
    
        </div>
        
         <div class="col-12">
                  <h3 class="card__title pointer" style="text-align: right;color: #0E6BA2;text-shadow: 1px 1px 2px #73707082;margin-bottom: 10px;">  المسلسلات </h3>

                        <div class="row row--grid">
                            <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in Series" :key="publish.id">
                                <div class="cardBox">
                                <div class="card">
                                    <a @click="GoToSeriesContent(publish)" class="card__cover">
                                        <img :src="publish.series_img" alt="">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                    </a>
                                    <h3 class="card__title"><a @click="GoToSeriesContent(publish)">{{ publish.series_name_ar }}</a></h3>
                                    
                                    <ul class="card__list" style="justify-content: center;" >
                                        <!-- <li>الفنان /</li> -->
                                        <li> مع {{ publish.cp_name }}</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
      <div class="col-12">
        <h3 class="card__title pointer" style="text-align: right;color: #0E6BA2;text-shadow: 1px 1px 2px #73707082;margin-bottom: 10px;">  الممثلين </h3>
        <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
       <template v-for="publish in ArtistsComedy" :key="publish.id">
                    <div class="col-6 col-sm-4 col-lg-3 col-xl-2" >
                                <div class="cardBox">
  <div class="card">
      <a @click="GoToArtistsContent(publish)" class="card__cover" style="height: 230px;">
                                        <img :src="publish.img" alt="">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                    </a>
                                    <h3 class="card__title" style="padding-bottom: 9px;margin-bottom: 7px;"><a @click="GoToArtistsContent(publish)">{{ publish.cp_name }}</a></h3>
                                    <ul style="justify-content: center;" class="card__list">
                                        <li style="margin-left: 0;">{{ publish.name_ar }}</li>
                                        <li></li>
                                    </ul>
    
  </div>
</div>
                    </div>
            </template>
        </div>
        </div>
        </div>
        </div>
      </div>
      <div class="tab6">
      <div class="container" style="padding-left:0;padding-right:0">
      
        </div>
      </div>
    </section>
  </div>

  <!-- end catalog -->
     
       
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import { watchEffect } from '@vue/runtime-core';
import { useToast } from "vue-toastification";
// import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  // components: { VueperSlides, VueperSlide },
  name: 'HomePage',
  async setup() {
    const router = useRouter();
    const cookie = useCookie();
    const toast = useToast();

    const carousel = ref([]);
    const All = ref([]);
    const art = ref([]);
    const comedy = ref([]);
    const Artists = ref([]);
    const Series = ref([]); 
    const ArtistsComedy = ref([]);


        // watchEffect(() => {
        //     if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
        //     let urlParams = new URLSearchParams(window.location.search);
        //     // console.log(urlParams.get('msisdn'))
        //     if(urlParams.has('phoneNumber')) {
        //         HTTP.post(`CheckLogin.php?username=2491`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
        //             if (res.data.status == 1 ) {
        //                 cookie.setCookie('msisdn','2491'+urlParams.get('phoneNumber'), { expire: 60 * res.data.MINTUES, })
        //                 cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
        //                 cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
        //                 toast.success("مرحبا بك معنا  ", { timeout: 1000 });
        //             }
        //             if(cookie.getCookie("Content_id")!=null){
        //                 toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
        //                 setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("Content_id") } }), 2000);
        //             } else {
        //                 setTimeout(() => router.push({ path: "/" }), 2500);
        //             }
        //         })
        //     }
        // }
        // });
        watchEffect(() => {
            if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
            let urlParams = new URLSearchParams(window.location.search);
            // console.log(urlParams.get('msisdn'))
            if(urlParams.get('phoneNumber') != null) {
                 if(urlParams.get('phoneNumber').length === 12) {
              // console.log(urlParams.get('phoneNumber'))
              if(urlParams.has('phoneNumber')) {
                // HTTPSPA.post(`CheckLogin.php?username=2491`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                HTTP.post(`CheckLogin.php?username=`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', urlParams.get('phoneNumber'), { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
                        // toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    }
                    if(cookie.getCookie("Content_id")!=null){
                        toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                        setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("Content_id") } }), 2000);
                    } else {
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                })
            }
            } else {
              if(urlParams.has('phoneNumber')) {
                HTTP.post(`CheckLogin.php?username=2491`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                // HTTPSPA.post(`CheckLogin.php?username=`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', `2491`+urlParams.get('phoneNumber'), { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
                        // toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    }
                    if(cookie.getCookie("Content_id")!=null){
                        toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                        setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("Content_id") } }), 2000);
                    } else {
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                })
            }
            }
            }
            
          } else {
              HTTP.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+`&serviceId=2222&operatorID=2`).then((res) => {
                if (res.data.status === 0 ) {
                    this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
                }
              })
          }
        });
    try {
      await HTTP.get(`GetArtistsByCategory.php?ID=4`).then((res) => {
        Artists.value = res.data.Artists; 
      });
    } catch (err) {
      console.log(err);
    }
    try {
      await HTTP.get(`GetArtistsByCategory.php?ID=5`).then((res) => {
        ArtistsComedy.value = res.data.Artists; 
      });
    } catch (err) {
      console.log(err);
    }
    try {
      await HTTP.get(`GetAllSeries.php`).then((res) => {
        Series.value = res.data.Series; 
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await HTTP.get(`GetAllContent.php?Content&LIMIT=15&OFFSET=0`).then((res) => {
        carousel.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }
    try {
      await HTTP.get(`GetAllArtists.php`).then((res) => {
        All.value = res.data.Artists; 
      });
    } catch (err) {
      console.log(err);
    }
    try {
      await HTTP.get(`GetContentByCategory.php?cat_id=4&LIMIT=15&OFFSET=0`).then((res) => {
        art.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }
    try {
      await HTTP.get(`GetContentByCategory.php?cat_id=5&LIMIT=15&OFFSET=0`).then((res) => {
        comedy.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }
    const getFeed = (publish) => {
      cookie.setCookie('Content_id', publish.id)
      cookie.setCookie('cat_id', publish.cat_id);
      if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
          HTTP.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+"&serviceId=2222&operatorID=2"
          ).then((res) => {
              if (res.data.MINTUES > 0) {
                // this.$router.push("/Content");
                router.push({ name: "Content", params: { Content_id: publish.id } });
              } else {
                router.push("/Login");
              }
          });
      } else {
          cookie.setCookie('id', publish.id);
          // router.push({ name: "Landing" });
          window.location.href="http://sudanipay.com/subscription/nashoof-sd/nashoof-sd";
      }
    };

    const GoToArtistsContent = (publish) => {
      if(cookie.isCookieAvailable("artist_id")){
        cookie.removeCookie("artist_id")
      }
      cookie.setCookie('artist_id', publish.id)
      router.push("/ArtistContent");
    };

    const GoToSeriesContent = (publish) => {
      if(cookie.isCookieAvailable("series_id")){
        cookie.removeCookie("series_id")
      }
      cookie.setCookie('series_id', publish.id)
      router.push("/SeriesContent");
    };
    return { carousel, All, art, comedy,Artists,Series,ArtistsComedy, getFeed ,GoToArtistsContent,GoToSeriesContent};
    
  },
 
}
</script>

<style>
 .cardBox {
  width: auto;
height: 26vh;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
border-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
margin-bottom: 1vh;
margin-top: 1vh;
}
.card {
  position: absolute;
  width: 95%;
  height: 95%;
  background: #026097;
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
    margin-top: 0;
}

.card h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 800;
  pointer-events: none;
  opacity: 0.1;
}
/*
 span {
  top: 75%;
} 
*/
.card .content h3 {
  font-size: 3rem;
  padding-bottom: 10px;
}
.card .content p {
  font-size: 1.6rem;
  line-height: 25px;
}
.card .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card:hover .content {
  transform: translateY(0);
  opacity: 1;
}

.cardBox::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 150%;
  background: #40E0D0;
background: -webkit-linear-gradient(to right, #026097, #42b3f4, #40E0D0);
background: linear-gradient(to right, #026097, #42b3f4, #40E0D0);
  animation: glowing01 200s linear infinite;
  transform-origin: center;
  animation: glowing 200s linear infinite;
}


.cardBox2 {
  width: auto;
height: 26vh;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
border-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
margin-bottom: 1vh;
margin-top: 1vh;
}
.cardBox2::before {
  content: "";
  position: absolute;
  width: 140%;
  height: 70%;
  background: #40E0D0;
background: -webkit-linear-gradient(to right, #026097, #42b3f4, #40E0D0);
background: linear-gradient(to right, #026097, #42b3f4, #40E0D0);
  animation: glowing01 200s linear infinite;
  transform-origin: center;
  animation: glowing 200s linear infinite;
}
.card2 {
  position: absolute;
  width: 95%;
  height: 95%;
  background: #fff;
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset; */
    margin-top: 0;
}

.card2 h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 800;
  pointer-events: none;
  opacity: 0.1;
}
/*
 span {
  top: 75%;
} 
*/
.card2 .content h3 {
  font-size: 3rem;
  padding-bottom: 10px;
}
.card2 .content p {
  font-size: 1.6rem;
  line-height: 25px;
}
.card2 .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card2:hover .content {
  transform: translateY(0);
  opacity: 1;
}




@keyframes glowing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
 .vueperslides__arrow svg {
  vertical-align: middle;
  stroke: #999494;
  fill: #45a6fb;
  width: 3.5em;
  padding: 1em;
  stroke-width: 1;
  box-sizing: border-box;
}







@media (min-width: 768px) and (max-width: 991px) {
    /* Show 4th slide on md if col-md-4*/
    .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -33.3333%;  /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    /* Show 3rd slide on sm if col-sm-6*/
    .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -50%;  /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible;
    }
}
@media (min-width: 576px) {
    .carousel-item {
        margin-right: 0;
    }
    /* show 2 items */
    .carousel-inner .active + .carousel-item {
        display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
        transition: none;
    }
    .carousel-inner .carousel-item-next {
        position: relative;
        transform: translate3d(0, 0, 0);
    }
    /* left or forward direction */
    .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    /* farthest right hidden item must be also positioned for animations */
    .carousel-inner .carousel-item-prev.carousel-item-right {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        visibility: visible;
    }
    /* right or prev direction */
    .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
    
}
.carousel {
    position: relative;
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0,0,0);
    direction: ltr;
}
/* MD */
@media (min-width: 768px) {
    /* show 3rd of 3 item slide */
    .carousel-inner .active + .carousel-item + .carousel-item {
        display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
        transition: none;
    }
    .carousel-inner .carousel-item-next {
        position: relative;
        transform: translate3d(0, 0, 0);
    }
    /* left or forward direction */
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    /* right or prev direction */
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}
/* LG */
@media (min-width: 991px) {
    /* show 4th item */
    .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
        display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
        transition: none;
    }
    /* Show 5th slide on lg if col-lg-3 */
    .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -25%;  /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible;
    }
    /* left or forward direction */
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    /* right or prev direction //t - previous slide direction last item animation fix */
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}
.pointer {
  cursor: pointer;
}
@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,400,700);
/* Component Needs */
.pc-tab > input,
.pc-tab section > div {
  display: none;
}
#tab0:checked ~ section .tab0,
#tab1:checked ~ section .tab1,
#tab2:checked ~ section .tab2,
#tab3:checked ~ section .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5,
#tab6:checked ~ section .tab6 {
  display: block;
}
#tab0:checked ~ nav .tab0,
#tab1:checked ~ nav .tab1,
#tab2:checked ~ nav .tab2,
#tab3:checked ~ nav .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5,
#tab6:checked ~ section .tab6 {
  color: red;
}
/* Visual Styles */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  font-weight: 100;
  font-size: 60px;
  color: #e74c3c;
}
.pc-tab {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  margin: 0 auto;
}
.pc-tab ul {
  list-style: none;
justify-content: center;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
padding-right: 0%;
padding-left: 0%;
}
.pc-tab ul li label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Cairo", sans-serif;
  color: #fff;
  background-image: linear-gradient(0deg,#085786 0,#71C6F7 );
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 5px;
  position: relative;
  z-index: 100;
  transition: color 0.5s;
}
.pc-tab ul li label:hover {
  background-color: #52cbff;
}
.pc-tab ul li label:active {
  background-color: #52cbff;
}
.pc-tab ul li:not(:last-child) label {
  border-right-width: 0;
}
.pc-tab section {
  font-family: "Droid Serif";
  clear: both;
}

.pc-tab section div h2 {
  margin: 0;
  font-family: "Raleway";
  letter-spacing: 1px;
  color: #34495e;
}
#tab0:checked ~ nav .tab0 label,
#tab1:checked ~ nav .tab1 label,
#tab2:checked ~ nav .tab2 label,
#tab3:checked ~ nav .tab3 label,
#tab4:checked ~ nav .tab4 label,
#tab5:checked ~ nav .tab5 label,
#tab6:checked ~ nav .tab6 label {
  color: #1d3544;
  background-image: linear-gradient(0deg,#9ed7f9 0,#71C6F7 );
  position: relative;
}
#tab0:checked ~ nav .tab0 label:after,
#tab1:checked ~ nav .tab1 label:after,
#tab2:checked ~ nav .tab2 label:after,
#tab3:checked ~ nav .tab3 label:after,
#tab4:checked ~ nav .tab4 label:after,
#tab5:checked ~ nav .tab5 label:after,
#tab6:checked ~ nav .tab6 label:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  right: 0;
  bottom: -1px;
}
footer {
  margin-top: 50px;
  font-size: 14px;
  color: #ccc;
  text-align: center;
}
footer a {
  color: #aaa;
  text-decoration: none;
}
.card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(0deg,#004269 0,#71C6F7 );
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 15px 20px rgba(0,0,0,0.30), 0 15px 20px rgba(0,0,0,0.22);
}
@media (max-width:540px) {
     .pc-tab ul {
        justify-content: flex-start;
     }
 }




.section-title {
	padding-left: .5rem;
}

/* Ableton styles for slideable navigation with blurry effect */
.ableton {
	color: #ff764d
}

.ab__nav-slidder-wrapper {
	position: relative;
}

.ab__nav-slidder-wrapper::after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 1.25rem;
	width: 3rem;
	background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
}

.ab__nav-slidder {
	overflow-x: auto;
	padding-bottom: 1.25rem;
}

.ab__nav-list {
	display: flex;
}

.ab__nav-list-item {
	padding: 0 .5rem;
	min-width: 17rem;
}

.ab__nav-list-title {
	margin-bottom: .5em;
}

/*==== End ====*/

/* Youtube styles for slideable navigation with blurry effect */
.youtube {
	color: #ff0100;
}

.yt__filter-slider-wrapper {
	position: relative
}

.yt__filter-slider-wrapper::before,
.yt__filter-slider-wrapper::after {
	position: absolute;
	font-size: 1.5rem;
	width: 8rem;
}




.yt__filter-slider {
	overflow-x: auto;
	padding-bottom: 1.25rem
}

.yt__filter-list {
	display: flex;
}

.yt__filter-list-item {
	padding: .5em .8em;
	background-color: #0000000d;
	border: 1px solid #0000001a;
	border-radius: 1.5rem;
  color: #595555;
}

.yt__filter-list-item:first-child {
	margin-right: .4rem;
}

.yt__filter-list-item + .yt__filter-list-item {
	margin: 0 .4rem;
}

.yt__filter-list-item:hover {
	cursor: pointer;
	background-color: #0000001a;
}

.active {
	background-color: #606060;
	color: #fff;
}

.active:hover {
	background-color: #909090;
}





.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
  text-align: center;
color: #fff;
font-size: 20px;
font-weight: 600;
background: #53505270;
width: 100%;
height: auto;
border-bottom: 3px dotted #fff;
font-family: 'Cairo', sans-serif;
}
.vueperslides__parallax-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
}
 .vueperslides__arrow {
  position: absolute;
  font-size: inherit;
  color: inherit;
  text-align: center;
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  z-index: 2;
  line-height: 1;
  background:transparent;
  border-radius: 50px;
}

.vueperslides--fade .vueperslide {
 
  /* border: 10px solid rgb(255, 255, 255); */
  box-shadow: 0px 0px 4px #0837a6;
  border-radius: 20px;
  /* margin-top: 2px; */
}

/*==== End ====*/

/* .flex {
     -webkit-box-flex: 1;
     -ms-flex: 1 1 auto;
     flex: 1 1 auto
 }

 @media (max-width:991.98px) {
     .padding {
         padding: 1.5rem
     }
 }

 @media (max-width:767.98px) {
     .padding {
         padding: 1rem
     }
 } */
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(0deg,#0f4a6c 0,#068FDD );
  width: 100%;
  height: auto;
  border-bottom: 3px dotted #fff;
  font-family: 'Cairo', sans-serif;
  margin-top: 19vh;
  height: 6vh;
}
.vueperslides__bullets {
  display: none;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.vueperslides__arrows--outside .vueperslides__arrow--prev {
  right: auto;
  left: -0.5em;
  z-index: 9999;
}
.vueperslides__arrows--outside .vueperslides__arrow--next {
  left: auto;
  right: 0.5em;
}
</style>